<template>
    <!-- NOTE: IMPORTANT: -->
    <!-- Any changes made to this file's html/vue needs to be made in 2 different places -->
    <!-- because there is an "v-if" and "v-else" used here that both use the same content but it is a workaround -->
    <!-- to handle both the legacy code implementation as well as the proper design system implementation -->
    <!-- please do not miss doing this in both places, if you don't, then 1 place will not reflect the changes -->
    <!-- NOTE: IMPORTANT: -->
    <div>
        <hb-modal v-model="modalDialog" size="lg" @click="closeDialogue">
                    <template v-slot:title>
                        <!-- <hb-btn icon tooltip="Back" @click="closeEdit" mdi-code="mdi-chevron-left" active-state-off
                            :color="state === 'view' ? '#101318' : ''" :hover-background-color="modal ? '#CAEAEA' : ''">
                        </hb-btn> -->
                        <span class="ml-1">
                            Invoice {{ invoice.invoice_number }}
                        </span>
                    </template>

            <template v-slot:content>
                <div>
                    <div class="pr-4 pa-4" style="background: #F9FAFB;">
                        <hb-header one-action >
                            <hb-mini-profile type="account" :title="contact.Business.name" 
                    :phone="contact.Phones[0].phone | formatPhone" :status="contact.status ? contact.status : undefined"
                     :can-text="true" :email="contact.email" :representative-name="contact.first + ' ' + contact.last"
                        :address="computedBusinessAddress" :id-verified="contact.email_verified
                            ? contact.email_verified
                            : undefined
                            " :account-space-count="calculateLength"
                        :account-space-type="contact.Leases && contact.Leases?.length === 1 ? contact?.Leases[0]?.Unit?.type : ''"
                        />
                        </hb-header>
                    </div>
                    <div class="px-4 py-4">
                        <v-row class="mx-0">
                            <v-col cols="9" class="pa-0 pr-0">
                                <v-card class="hb-elevation-medium hb-border-default">
                                    <v-card-title class=" bg-colo-light d-flex align-center ma-0 pa-0 inv-header-cell"
                                        style="flex-wrap: unset;">
                                        <v-col cols="3" class="d-flex align-center">
                                            <span class="ml-3 subtitle-1 font-weight-medium">Invoice #</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="3" class="d-flex align-center">
                                            <span class="ml-3 subtitle-1 font-weight-medium">Invoice Date:</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="3" class="d-flex align-center">
                                            <span class="ml-3 subtitle-1 font-weight-medium">PO Number</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="3" class="d-flex align-center">
                                            <span class="ml-3 subtitle-1 font-weight-medium">Due Date</span>
                                        </v-col>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text class="d-flex pa-0 ma-0 hb-default-font-size">
                                        <v-col cols="3" class="d-flex align-center pr-0">
                                            <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{
            invoice.invoice_number }}</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="3" class="d-flex align-center pr-0">
                                            <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{
            invoice.invoice_date
                                                | formatDateServices }}</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="3" class="d-flex align-center pr-0">
                                            <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{
                                                invoice.po_number}}</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="3" class="d-flex align-center pr-0">
                                            <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{
                                                invoice.invoice_due
                                                | formatDateServices }}</span>
                                        </v-col>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>

                        <v-col class="pa-4 pr-4 ml-1">
                            <hb-header>
                                <span class="hb-font-header-3-medium">Billing Period : </span>
                                <span> {{ invoice.invoice_from | formatDateServices }} - {{ invoice.invoice_to | formatDateServices}}</span>
                                
                            </hb-header>
                            <v-card class="hb-border-default" elevation="0">
                                <v-card-title class="bg-colo-light d-flex align-center ma-0 pa-0 inv-header-cell"
                                    style="flex-wrap: unset;">
                                    <v-col cols="3" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Total SQM</span>
                                    </v-col>
                                    <v-col cols="3" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Total Centres</span>
                                    </v-col>
                                    <v-col cols="3" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Total Units</span>
                                    </v-col>
                                    <v-col cols="3" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">SQM Discount</span>
                                    </v-col>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pa-0 ma-0">
                                    <v-row class="d-flex pa-0 mx-0">
                                        <v-col cols="3" class="d-flex hb-font-body-medium align-center ">
                                            <span > {{invoice.total_area?.value}}   </span>
                                        </v-col>
                                        <v-col cols="3" class="d-flex hb-font-body-medium align-center" >
                                            <span >  {{invoice.total_properties}}</span>
                                        </v-col>
                                        <v-col cols="3" class="d-flex hb-font-body-medium align-center">
                                            <span > {{invoice.total_units}}</span>
                                        </v-col>
                                        <v-col cols="3" class="d-flex hb-font-body-medium align-center">
                                            <span v-if="invoice.discount.value"  >  {{invoice.discount?.value}}%   </span>
                                            <span v-else>  -  </span>
                                        </v-col>
                                        
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="pa-4 pr-4 ml-1">
                            <hb-header>
                                <HbPageHeader
                                    title="Summary"
                                />
                                
                            </hb-header>
                            <v-card class="hb-border-default" elevation="0">
                                <v-card-title class=" bg-colo-light d-flex align-center ma-0 pa-0 inv-header-cell"
                                    style="flex-wrap: unset;">
                                    <v-col cols="3" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Department</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Centres</span>
                                    </v-col>
                                    <v-col cols="1" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Units</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Amount</span>
                                    </v-col>
                                    <v-col cols="1" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">GST</span>
                                    </v-col>
                                    <v-col cols="1" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Disc</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center">
                                        <span class="subtitle-2 font-weight-medium">Amt (inc GST)</span>
                                    </v-col>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pa-0 ma-0">
                                    <v-row class="d-flex pa-0 mx-0" v-for="(line, index) in invoice.departments" :key="index">
                                        <v-col cols="3" class="hb-font-body-medium align-center justify-end">
                                            <span  class= "font-weight-medium"> {{line.name }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex hb-font-body-medium align-center" >
                                            <span >  {{line.total_properties}}</span>
                                        </v-col>
                                        <v-col cols="1" class="d-flex hb-font-body-medium align-center">
                                            <span > {{line.total_units}}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center ">
                                            <span > {{line.total_price | formatMoney }}   </span>
                                        </v-col>
                                        <v-col cols="1" class="d-flex align-center">
                                            <span >  {{line.total_tax | formatMoney }}   </span>
                                        </v-col>
                                        <v-col cols="1" class="d-flex align-center">
                                            <span >  {{line.total_discount | formatMoney }}   </span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex hb-font-body-medium align-center justify-end ">
                                            <span >  {{line.total_sum | formatMoney }} </span>
                                        </v-col>
                                        
                                    </v-row>
                                  
                                    <v-divider></v-divider>
                                    <v-row class="bg-colo-light d-flex pa-0 mx-0	hb-font-body">
                                        <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                                            <span class="" >Sub Total (excl GST):</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                                            <span class="">{{ invoice.total_price | formatMoney }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row class=" bg-colo-light d-flex pa-0 mx-0 hb-font-body">
                                        <v-col cols="10"  class="d-flex align-center justify-end px-0 py-1">
                                            <span class="">GST Amount</span>
                                        </v-col>
                                        <v-col cols="2"class="d-flex align-center justify-end pl-0 py-1">
                                            <span class="">{{ invoice.total_tax | formatMoney }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="bg-colo-light d-flex pa-0 mx-0">
                                        <v-col cols="10"  class="d-flex align-center justify-end px-0 py-1">
                                            <span class=" font-weight-bold
                                             ">Invoice Total (inc GST)</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                                            <span class="font-weight-bold">{{ invoice.total_sum | formatMoney }}</span>
                                        </v-col>
                                    </v-row>
                                   
                                </v-card-text>
                            </v-card>
                          <v-row class="mx-0 mt-4">
                            <span class="hb-font-header-3" >Payment Details:</span>
                          </v-row>
                          <v-row class="d-flex pa-0 mx-0">
                        
                                <div class="hb-font-header-3"> <span class="hb-font-header-3-medium" cols="1">  Account Name: </span> {{ account.account_name  }} &nbsp; </div> 
                           
                                <div class="hb-font-header-3"> <span class="hb-font-header-3-medium" cols="1">  BSB:</span> {{ account.bsb_number  }} &nbsp; </div> 
                           
                                <div class="hb-font-header-3"> <span class="hb-font-header-3-medium" cols="1">  Account Number: </span> {{ account.account_number  }} &nbsp; </div>
                           
                          </v-row>
                        </v-col>
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="secondary" @click="openPONumberDialog">Update PO Number</hb-btn>
                <hb-btn color="secondary" @click="download(invoice_id)">Print / Download</hb-btn>
                <hb-btn color="secondary" @click="sendNaInvoice">Send Invoice</hb-btn>
            </template>
        </hb-modal>
        <hb-modal size="medium" title="Update PO Number"  v-model="poNumberDialog">
            <template v-slot:content>        
                <v-text-field class="py-4 px-6"
                    v-model="updatedPONumber"
                    v-validate="{ regex: /^[\x20-\x7E]{1,45}$/ }"
                    data-vv-scope="default" 
                    data-vv-name="po_number" 
                    data-vv-as="PO Number"
                    :error="errors.has('default.po_number')"
                    label="Update PO Number"
                    placeholder="Enter new PO Number"
                ></v-text-field>
            </template>
            <template v-slot:right-actions>
                <hb-btn @click="savePONumber" color="primary" :disabled="!isPONumberEdited">Save</hb-btn>
            </template>
        </hb-modal>

        <hb-modal
            size="medium"
            title="Notice"
            v-model="guidanceDialog"
            guidance
            :footer-cancel-option="false"
        >
            <template v-slot:content>
                <div class="py-4 px-6">
                    PO Number updated successfully. Please close and reopen the invoice modal to see the changes.
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn @click="guidanceDialog = false">OK</hb-btn>
            </template>
        </hb-modal>
    </div>
   
</template>

<script type="text/babel">
import Modal from '../assets/Modal.vue';
import PulseLoader from '../assets/DotLoader.vue';
import DropdownMenu from '../assets/DropdownMenu.vue';
import RecordPayment from '../leases/RecordPayment.vue';
import ViewInvoice from './ViewInvoice.vue';
import EditInvoice from './EditInvoice.vue';
import CreateInvoice from './CreateInvoice.vue';
import HbDatePicker from '../assets/HummingbirdDatepicker';
import SendInvoice from "../assets/SendInvoice.vue";
import Status from '../includes/Messages.vue';
import Loader from '../assets/CircleSpinner.vue';
import api from '../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import { mapGetters } from 'vuex';
import { notificationMixin } from '../../mixins/notificationMixin.js';
import PaymentsNotificationModal from '../payments/PaymentsNotificationModal.vue';

export default {
    name: "DisplayNAInvoice",
    mixins: [notificationMixin],
    data() {
        return {
            // contact: {},
            account: {},
            state: 'view',
            invoice: {},
            payments: [],
            isLoadingFlag: false,
            sendInvoiceModal: false,
            poNumberDialog: false, 
            updatedPONumber: '', 
            guidanceDialog: false,
            
        }
    },
    components: {
        Modal,
        PulseLoader,
        DropdownMenu,
        Loader,
        RecordPayment,
        ViewInvoice,
        EditInvoice,
        CreateInvoice,
        Status,
        HbDatePicker,
        PaymentsNotificationModal,
        SendInvoice
    },
    created() {
        if (this.type == 'create') this.state = 'create';
        this.fetchData();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'authenticationStore/isAdmin',
            hasPermission: 'authenticationStore/rolePermission',
            NationalAccount: 'onBoardingStore/getNationalAccount',
            properties: "propertiesStore/filtered",

        }),
        calculateLength() {
            let leases = this.contact?.Leases.filter(lease => !lease.end_date)
            return leases?.filter((lease) => {
                return this.filterLeases(lease.Unit.property_id);
            })?.length;
        },
        modalDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        computedBusinessAddress() {
            if (
                this.contact.Business &&
                this.contact.Business.Address
            ) {
                const address_1 = this.contact.Business.Address.address
                ? this.contact.Business.Address.address
                : "";
                const address_2 = this.contact.Business.Address.address2
                ? this.contact.Business.Address.address2
                : "";

                let address = address_1;
                if (address_2 !== "") {
                if (address !== "") {
                    address += ", ";
                }
                address += address_2;
                }

                if (address !== "") {
                address += ", ";
                }

                address += this.$options.filters.formatAddress(this.contact.Business.Address);

                return address;
            } else {
                return undefined;
            }
        },
        isPONumberEdited() {
        return this.updatedPONumber !== this.invoice.po_number;
        }
    },
    methods: {
        async fetchData() {
            this.isLoadingFlag = false
            if (!this.invoice_id) return;
            
            api.get(this, api.NATIONAL_ACCOUNT + this.account_id + '/invoices/' + this.invoice_id).then(r => {

                this.invoice = r.invoice;
                this.invoice.date = moment(this.invoice.date).format('MM/DD/YYYY');
                this.invoice.due = moment(this.invoice.due).format('MM/DD/YYYY');
                this.isLoadingFlag = true
               


                // api.get(this, api.CONTACTS + this.contact_id ).then(r => {
                //     this.contact = r.contact;
                // });
                api.get(this, api.NATIONAL_ACCOUNTS).then(r => {
                    this.account = r;
                });

            });
            
           
        },
        openPONumberDialog() {
            console.log("Opening PO Number dialog"); 
            this.updatedPONumber = this.invoice.po_number;
            this.poNumberDialog = true;
        },
        async savePONumber() {
            const poNumberValid = await this.$validator.validate('default.po_number');
            if (!poNumberValid) {
                this.showMessageNotification({
                    type: 'error',
                    description: 'There are errors in your PO Number field. Please correct them before continuing.',
                    list: this.errors.items
                });
                return; 
            }
            try {
                const payload = { po_number: this.updatedPONumber };
                await api.put(this, api.NATIONAL_ACCOUNT + this.account_id + '/invoices/' + this.invoice_id + '/update-po-number', payload).then(async (res) => {
                this.guidanceDialog = true;
                this.poNumberDialog = false; 
            })
            } catch (error) {
                this.showMessageNotification({
                    type: "error",
                    description: "Failed to update PO Number. Please try again.",
                });
            }
        },
        filterLeases(property_id) {
            return this.properties.some((i) => i.id === property_id);
        },
        async download(invoice_id) {
            console.log("start");
            var data = {
                type: 'national-accounts-invoice',
                format: 'pdf',
                request_id: invoice_id,
                national_accounts_id: this.account_id,
            };

            let response = await api.post(this, api.REPORTS + 'download-pdf', data);
            console.log(response);
            const arr = new Uint8Array(response.data);
            var blob = new Blob([arr], { type: 'application/pdf' });
            console.log(blob);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            window.open(link.href, '_blank');

            // link.download = "Invoice_" + this.invoice.number;
            // link.click();

            //this.$store.commit('notificationsStore/startDocumentDownload', response);
        },

        async sendNaInvoice() {
            try {
                let responseUrl = api.NATIONAL_ACCOUNT + this.invoice_id + "/send_invoice";
                let currentDate = new Date().toISOString().split('T')[0];
                const payload = {
                    na_account_id: this.account_id,
                    date: currentDate,
                    property_id: null,
                    contact_id: this.contact.id
                };

                const response = await api.post(this, responseUrl, payload);
                console.log("Invoice sent successfully:", response);

                this.showMessageNotification({
                    type: "success",
                    description: "Invoice sent successfully."
                });
            } catch (error) {
                console.error("Error sending invoice:", error);
                this.showMessageNotification({
                    type: "error",
                    description: "Failed to send the invoice. Please try again."
                });
            }
        },

        closeDialogue() {
            if (!this.adjustmentMode) {
                this.$emit('close')
            } else {
                this.invoice = JSON.parse(JSON.stringify(this.invoiceBackup));
            }

            this.closeEdit()
        },
    },

    props: ['invoice_id', 'account_id', 'contact', 'type', 'fromVDialog', 'value', 'modal', 'fromMasterVueFile'],
    watch: {
        invoice_id: function () {
            this.state = 'view';
            this.fetchData();
            //    this.fetchPayments();
        },
    }
}


</script>


<style scoped>
.slide-out-header-nav {
    border-bottom: 1px solid #dce8ef;
}

.slide-out-header-position-normal {
    position: static;
}
.bg-colo-light {
    background-color: #F9FAFB;
}

.key-label {
    background: #f4f6f8;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101318;
}

.display-invoice-header-title-wrapper {
    background: #E0F5F5;
    height: 52px;
}
</style>